// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性

$(function () {
  if ($('#InPageMainImage').length) {
    $('#InPageMainImage').css({
      "margin-top": $('#Header').outerHeight()
    })
  }

  $(document).on("click", "#NavOpen", function () {
    $(this).toggleClass("Active");
    $("#Nav").toggleClass("Active");
  })
  $(document).on("click", "#Nav a", function () {
    $("#NavOpen").toggleClass("Active");
    $("#Nav").toggleClass("Active");
  })
  if ($('#Postage').length) {
    $.getJSON("https://soy-mameya.com/wp-json/acf/v3/postage", function (data) {

      $("#Postage").append('<a href="' + data[0].acf.pdf + '" target="_blank">送料について</a>');
    });
  }

})
