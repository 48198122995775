const moment = require('moment');
import vegas from "vegas";
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
    // console.log(moment().format("YYYY-MM-DD"));
    if ($('#TopPageMainImage').length) {
        $("#TopPageMainImage").vegas({
            timer: false,
            slides: [
                { src: "assets/img/TopPage/Slide/Photo1.jpg" },
                { src: "assets/img/TopPage/Slide/Photo2.jpg" },
                { src: "assets/img/TopPage/Slide/Photo3.jpg" }
            ]
        });
        if ($(window).width() <= 640) {
            $("#TopPageMainImage").css({
                height: $(window).height() - $("#Header").outerHeight() - 80,
                "margin-top": $('#Header').outerHeight()
            })
        } else {
            $("#TopPageMainImage").css({
                height: $(window).height() - $("#Header").outerHeight(),
                "margin-top": $('#Header').outerHeight()
            })
        }
    }
});
