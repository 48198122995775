$(document).ready(function () {
  var urlHash = location.hash;
  if (urlHash) {
    $("html, body").animate({
      scrollTop: $(urlHash).offset().top - $("#Header").outerHeight()
    })

  }
  $(".Anker").click(function () {
    var t;
    var id = $(this).attr("href");
    var result = id.replace('/#', '#');
    console.log(result);
    t = $(result);
    $("html, body").animate({
      scrollTop: t.offset().top - $("#Header").outerHeight()
    });
    return false;
  })
});
